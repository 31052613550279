import actionTypes from '../actions/actionTypes';
import { DRIP_ACC } from '../constants';
import { call, cancelled, put, select, takeLatest } from 'redux-saga/effects';
import { waitFor } from '../utils/waitFor';

export function claimTokensFunction(userAccount) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await userAccount.signTransaction(
        {
          actions: [
            {
              account: DRIP_ACC,
              name: 'claimcoin',
              authorization: [
                {
                  actor: userAccount.accountName,
                  permission: userAccount.requestPermission,
                },
              ],
              data: {
                wallet: userAccount.accountName,
              },
            },
          ],
        },
        {
          blocksBehind: 3,
          expireSeconds: 30,
        }
      );
      resolve(response);
    } catch (error) {
      console.error('ERROR', error);

      reject(error);
    }
  });
}

export function* resolveClaimTokensSaga() {
  try {
    const userAccount = yield select((state) => state.login.userAccount);
    yield call(claimTokensFunction, userAccount);
    const result = yield put({
      type: actionTypes.CLAIM_ALL_TOKENS_SUCCESS,
    });
    yield call(waitFor, 2);
    yield put({ type: actionTypes.GET_KEIKE_TOKENS });
    yield put({
      type: actionTypes.GET_USER_WALLET_COINS,
    });
    yield put({
      type: actionTypes.GET_USER_CONTRACT_COINS,
    });
  } catch (error) {
    console.error('ERROR', error);
    yield put({
      type: actionTypes.CLAIM_ALL_TOKENS_ERROR,
      payload: error,
    });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actionTypes.CLAIM_ALL_TOKENS_CANCELLED,
      });
    }
  }
}

export function* claimAllTokensSaga() {
  yield takeLatest(actionTypes.CLAIM_ALL_TOKENS_START, resolveClaimTokensSaga);
}
