import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Stack,
  Button,
  CircularProgress,
  Card,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { claimTokens } from "../actions/loginActions";
import actionTypes from "../actions/actionTypes";

function Redeemer() {
  const readyToClaim = "Ready to claim!";
  const timeToClaim = useSelector((state) => state.login.timeToClaim);
  const needReload = useSelector((state) => state.login.needReload);
  const [timeToClaimString, setTimeToClaimString] = useState(timeToClaim);
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    const show =
      timeToClaim === undefined || timeToClaim === null || needReload;
    setShowSpinner(show);
  }, [timeToClaim, needReload]);
  useEffect(() => {
    const i = setInterval(() => {
      dispatch({
        type: actionTypes.GET_KEIKE_TOKENS,
      });
      dispatch({
        type: actionTypes.GET_TIME_TO_CLAIM,
      });
      clearInterval(i);
    }, 3000);
    return () => {
      clearTimeout(i);
    };
  }, [needReload]);

  useEffect(() => {
    function calculateTimeLeft() {
      const difference = timeToClaim - Math.floor(Date.now() / 1000);
      let timeLeft = {};
      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (60 * 60 * 24)),
          hours: Math.floor((difference / (60 * 60)) % 24),
          minutes: Math.floor((difference / 60) % 60),
          seconds: Math.floor(difference % 60),
        };
        const timerComponents = Object.keys(timeLeft).map((interval) => {
          if (!timeLeft[interval]) {
            return;
          }

          return (
            <span key={interval}>
              {timeLeft[interval]} {interval}{" "}
            </span>
          );
        });
        return timerComponents;
      }
      return readyToClaim;
    }

    const id = setTimeout(() => {
      setTimeToClaimString(calculateTimeLeft());
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  });
  return (
    <Grid container justifyContent="center" mt={10}>
      {/* <Card sx={{ minWidth: 250, maxWidth: 300, margin: 'auto', padding: 5 }}>
        {showSpinner && (
          <Grid container justifyContent='center' mt={10}>
            <CircularProgress color='success' />
          </Grid>
        )}
        {!showSpinner && (
          <div>
            <Stack spacing={2}>
              <Typography
                variant='h5'
                component='div'
                sx={{ flexGrow: 1 }}
                textAlign='center'
                mt={1}
              >
                Claim your KEIKI
              </Typography>
              <Typography
                component='div'
                sx={{ flexGrow: 1 }}
                textAlign='center'
                mt={1}
              >
                {timeToClaimString}
              </Typography>
            </Stack>
            <Grid container justifyContent='center' mt={2}>
              <Button
                onClick={() => {
                  dispatch(claimTokens());
                }}
                disabled={timeToClaimString !== readyToClaim}
                variant='contained'
                size='large'
              >
                Claim
              </Button>
            </Grid>
          </div>
        )}
      </Card> */}
    </Grid>
  );
}

export default Redeemer;
