import { LOGGED_OUT } from '../constants';

export default {
  login: {
    accountName: undefined,
    userAccount: undefined,
    status: LOGGED_OUT,
    walletType: undefined,
    isLoginModalOpen: false,
    hasError: false,
    errorMessage: undefined,
    timeToClaim: undefined,
    balances: [],
    needReload: false,
  },
  coins: {},
};
