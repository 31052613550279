import actionTypes from '../actions/actionTypes';
import { call, cancelled, put, select, takeLatest } from 'redux-saga/effects';
import { stakeCoinsWaxCommand } from '../wax/stakeCoinsWaxCommand';
import { waitFor } from '../utils/waitFor';

export function* resolveStakeCoinsaSaga(coins) {
  try {
    const userAccount = yield select((state) => state.login.userAccount);
    const result = yield call(stakeCoinsWaxCommand, {
      coins: coins.payload,
      userAccount,
    });

    yield put({
      type: actionTypes.STAKE_COINS_SUCCESS,
    });
    yield call(waitFor, 1);
    yield put({
      type: actionTypes.GET_USER_CONTRACT_COINS,
    });
    yield put({
      type: actionTypes.GET_USER_WALLET_COINS,
    });
  } catch (error) {
    console.error('ERROR ON STAKE COINS', error);
    yield put({
      type: actionTypes.STAKE_COINS_ERROR,
      payload: error,
    });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actionTypes.STAKE_COINS_CANCELLED,
      });
    }
  }
}

export function* stakeCoinsSaga() {
  yield takeLatest(actionTypes.STAKE_COINS_START, resolveStakeCoinsaSaga);
}
