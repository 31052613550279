import actionTypes from '../actions/actionTypes';
import { call, cancelled, put, select, takeLatest } from 'redux-saga/effects';
import { unstakeCoinsWaxCommand } from '../wax/unstakeCoinsWaxCommand';
import { waitFor } from '../utils/waitFor';

export function* resolveUnstakeCoinsaSaga(coins) {
  try {
    const userAccount = yield select((state) => state.login.userAccount);
    const result = yield call(unstakeCoinsWaxCommand, {
      coins: coins.payload,
      userAccount,
    });

    yield put({
      type: actionTypes.UNSTAKE_COINS_SUCCESS,
    });
    yield call(waitFor, 1);
    yield put({
      type: actionTypes.GET_USER_CONTRACT_COINS,
    });
    yield put({
      type: actionTypes.GET_USER_WALLET_COINS,
    });
  } catch (error) {
    console.error('ERROR ON STAKE COINS', error);
    yield put({
      type: actionTypes.UNSTAKE_COINS_ERROR,
      payload: error,
    });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actionTypes.UNSTAKE_COINS_CANCELLED,
      });
    }
  }
}

export function* unstakeCoinsSaga() {
  yield takeLatest(actionTypes.UNSTAKE_COINS_START, resolveUnstakeCoinsaSaga);
}
