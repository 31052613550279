import { Wax } from '@eosdacio/ual-wax';
import { UalAdaptor } from './ualAdaptor';
import { APP_NAME } from '../constants';
import { Anchor } from 'ual-anchor';

class Wallets {
  constructor() {
    if (Wallets.instance) {
      return Wallets.instance;
    }

    /**
     * WAX Mainnet configuration
     */
    const myChain = {
      chainId:
        '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
      rpcEndpoints: [
        {
          protocol: 'https',
          host: 'wax.api.eosnation.io',
          port: '443',
        },
      ],
    };

    /**
     * WAX Testnet configuration
     */
    // let myChain = {
    //   chainId:
    //     'f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12',
    //   rpcEndpoints: [
    //     {
    //       protocol: 'https',
    //       host: 'testnet-wax.3dkrender.com',
    //       port: '',
    //     },
    //   ],
    // };

    this.wax = new Wax([myChain], { appName: APP_NAME });
    this.anchor = new Anchor([myChain], { appName: APP_NAME });

    this.wallets = new UalAdaptor(this.callback, [myChain], APP_NAME, [
      this.wax,
      this.anchor,
    ]);
    this.wallets.init();
    Wallets.instance = this;
  }
  callback(users) {}

  getWax() {
    return this.wax;
  }
  getAnchor() {
    return this.anchor;
  }
  getWallet() {
    return this.wallets;
  }
}

const instance = new Wallets();
Object.freeze(instance);

export default instance;
