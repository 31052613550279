import { COINS, COINS_TEMPLATES } from '../templateMap.js';

export function filterCoins(coins) {
  let result = [];
  if (coins) {
    coins.map((coin) => {
      if (COINS_TEMPLATES.includes(coin.template.template_id)) {
        result.push({
          template_id: coin.template.template_id,
          img: coin.data.img,
          name: coin.data.name.replace('•', '-'),
          rarity: coin.data.rarity,
          asset_id: coin.asset_id,
        });
      }
    });
    return result;
  }
}

export function filterCoinsStaked(coins) {
  let result = [];
  if (coins) {
    coins.map((coin) => {
      if (COINS[coin.template_id]) {
        result.push({
          template_id: coin.template_id,
          img: COINS[coin.template_id].img,
          name: COINS[coin.template_id].name,
          rarity: COINS[coin.template_id].rarity,
          asset_id: coin.asset_id,
          next_claim: coin.next_claim,
        });
      }
    });
    return result;
  }
}

export function filterCoinsValue(coins) {
  let result = {};
  if (coins) {
    coins.map((coin) => {
      const [amount, token] = coin.drip_amount.split(' ');
      result[coin.template_id] = {
        ...coin,
        amount,
        token,
      };
    });
  }
  return result;
}
