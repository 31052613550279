import * as React from 'react';
import Alert from '@mui/material/Alert';
import { Snackbar } from '@mui/material';
import { useDispatch } from 'react-redux';
import { cleanErros } from '../actions/loginActions';

export default function AlertMessage({ message, open }) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    const id = setTimeout(() => {
      dispatch(cleanErros());
    }, 3000);

    return () => {
      clearTimeout(id);
    };
  }, []);
  return (
    <Snackbar open={open} autoHideDuration={6000}>
      <Alert variant='filled' severity='error'>
        {message}
      </Alert>
    </Snackbar>
  );
}
