import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  openLoginModal,
  logoutUserAction,
  getUserWalletCoins,
  getUserContractCoins,
} from '../actions/loginActions';
import { APP_NAME } from '../constants';
import { Divider, Stack } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

export default function MenuAppBar() {
  const dispatch = useDispatch();
  const accountName = useSelector((state) => state.login.accountName);
  const balances = useSelector((state) => state.login.balances[0] || '0 KEIKI');

  return (
    <AppBar position='static'>
      <Toolbar>
        <IconButton
          size='large'
          edge='start'
          color='inherit'
          aria-label='menu'
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant='h6'
          component='div'
          sx={{ flexGrow: 1 }}
          style={{ fontFamily: 'Grandstander' }}
        >
          {APP_NAME}
        </Typography>
        {accountName && (
          <div>
            <Stack
              direction='row'
              divider={<Divider orientation='vertical' flexItem />}
              spacing={2}
            >
              <div>
                <Typography
                  mb={0}
                  variant='h6'
                  gutterBottom
                  component='div'
                  textAlign='center'
                >
                  {accountName}
                </Typography>
                <Typography
                  variant='caption'
                  display='block'
                  gutterBottom
                  textAlign='center'
                >
                  {balances}
                </Typography>
              </div>
              <MenuItem
                style={{ border: 'solid' }}
                onClick={() => dispatch(logoutUserAction())}
              >
                <Typography textAlign='center'>Logout</Typography>
              </MenuItem>
              <MenuItem>
                <IconButton
                  size='large'
                  aria-label='show 4 new mails'
                  color='inherit'
                  onClick={() => {
                    if (accountName === null || accountName === undefined)
                      return;
                    dispatch(getUserWalletCoins());
                    dispatch(getUserContractCoins());
                  }}
                >
                  <RefreshIcon />
                </IconButton>
              </MenuItem>
            </Stack>
          </div>
        )}
        {!accountName && (
          <MenuItem
            style={{ border: 'solid' }}
            onClick={() => dispatch(openLoginModal())}
          >
            <Typography textAlign='center'>Login</Typography>
          </MenuItem>
        )}
      </Toolbar>
    </AppBar>
  );
}
