import actionTypes from '../actions/actionTypes';

import initialState from './initialState';

export default function userRole(state = initialState.coins, action) {
  let newState;
  let update;
  switch (action.type) {
    case actionTypes.GET_USER_WALLET_COINS_SUCCESS:
      newState = {
        ...state,
        wallet: { ...action.payload },
      };
      return newState;
    case actionTypes.GET_USER_CONTRACT_COINS_SUCCESS:
      newState = {
        ...state,
        contract: { ...action.payload },
      };
      return newState;
    case actionTypes.GET_COINS_VALUES_SUCCESS:
      newState = {
        ...state,
        values: { ...action.payload },
      };
      return newState;

    default:
      return state;
  }
}
