import { DRIP_ACC } from '../constants';
export function getCoinsValueRequest(userAccount) {
  const TABLE = 'templates';
  return new Promise(async (resolve, reject) => {
    try {
      const { rows } = await userAccount.rpc.get_table_rows({
        json: true,
        code: DRIP_ACC,
        scope: DRIP_ACC,
        table: TABLE,
        limit: 100,
      });
      resolve(rows);
    } catch (error) {
      reject(error);
    }
  });
}
