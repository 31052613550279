export const GET_WAX_USER = 'GET_WAX_USER';
export const LOGIN_USER_WAX = 'LOGIN_USER_WAX';
export const LOGIN_USER_ANCHOR = 'LOGIN_USER_ANCHOR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGIN_CANCELLED = 'LOGIN_CANCELLED';
export const WAX_USER_RESULT = 'WAX_USER_RESULT';
export const WAX_USER_ERROR = 'WAX_USER_ERROR';
export const OPEN_LOGIN_MODAL = 'OPEN_LOGIN_MODAL';
export const CLOSE_LOGIN_MODAL = 'CLOSE_LOGIN_MODAL';
export const GET_KEIKE_TOKENS = 'GET_KEIKE_TOKENS';
export const GET_KEIKE_TOKENS_SUCCESS = 'GET_KEIKE_TOKENS_SUCCESS';
export const GET_KEIKE_TOKENS_ERROR = 'GET_KEIKE_TOKENS_ERROR';
export const GET_KEIKE_TOKENS_CANCELLED = 'GET_KEIKE_TOKENS_CANCELLED';
export const GET_TIME_TO_CLAIM = 'GET_TIME_TO_CLAIM';
export const GET_TIME_TO_CLAIM_SUCCESS = 'GET_TIME_TO_CLAIM_SUCCESS';
export const GET_TIME_TO_CLAIM_ERROR = 'GET_TIME_TO_CLAIM_ERROR';
export const GET_TIME_TO_CLAIM_CANCELLED = 'GET_TIME_TO_CLAIM_CANCELLED';
export const CLAIM_TOKENS = 'CLAIM_TOKENS';
export const CLAIM_TOKENS_SUCCESS = 'CLAIM_TOKENS_SUCCESS';
export const CLAIM_TOKENS_ERROR = 'CLAIM_TOKENS_ERROR';
export const CLAIM_TOKENS_CANCELLED = 'CLAIM_TOKENS_CANCELLED';
export const CLEAN_ERRORS = 'CLEAN_ERRORS';

export const GET_USER_WALLET_COINS = 'GET_USER_WALLET_COINS';
export const GET_USER_WALLET_COINS_SUCCESS = 'GET_USER_WALLET_COINS_SUCCESS';
export const GET_USER_WALLET_COINS_ERROR = 'GET_USER_WALLET_COINS_ERROR';
export const GET_USER_WALLET_COINS_CANCELLED =
  'GET_USER_WALLET_COINS_CANCELLED';

export const GET_USER_CONTRACT_COINS = 'GET_USER_CONTRACT_COINS';
export const GET_USER_CONTRACT_COINS_SUCCESS =
  'GET_USER_CONTRACT_COINS_SUCCESS';
export const GET_USER_CONTRACT_COINS_ERROR = 'GET_USER_CONTRACT_COINS_ERROR';
export const GET_USER_CONTRACT_COINS_CANCELLED =
  'GET_USER_CONTRACT_COINS_CANCELLED';

export const STAKE_COINS_START = 'STAKE_COINS_START';
export const STAKE_COINS_SUCCESS = 'STAKE_COINS_SUCCESS';
export const STAKE_COINS_ERROR = 'STAKE_COINS_ERROR';
export const STAKE_COINS_CANCELLED = 'STAKE_COINS_CANCELLED';

export const UNSTAKE_COINS_START = 'UNSTAKE_COINS_START';
export const UNSTAKE_COINS_SUCCESS = 'UNSTAKE_COINS_SUCCESS';
export const UNSTAKE_COINS_ERROR = 'UNSTAKE_COINS_ERROR';
export const UNSTAKE_COINS_CANCELLED = 'UNSTAKE_COINS_CANCELLED';

export const CLAIM_ALL_TOKENS_START = 'CLAIM_ALL_TOKENS_START';
export const CLAIM_ALL_TOKENS_SUCCESS = 'CLAIM_ALL_TOKENS_SUCCESS';
export const CLAIM_ALL_TOKENS_ERROR = 'CLAIM_ALL_TOKENS_ERROR';
export const CLAIM_ALL_TOKENS_CANCELLED = 'CLAIM_ALL_TOKENS_CANCELLED';

export const GET_COINS_VALUES_START = 'GET_COINS_VALUES_START';
export const GET_COINS_VALUES_SUCCESS = 'GET_COINS_VALUES_SUCCESS';
export const GET_COINS_VALUES_ERROR = 'GET_COINS_VALUES_ERROR';
export const GET_COINS_VALUES_CANCELLED = 'GET_COINS_VALUES_CANCELLED';

export default {
  GET_WAX_USER,
  LOGIN_USER_WAX,
  LOGIN_USER_ANCHOR,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGIN_ERROR,
  LOGIN_CANCELLED,
  WAX_USER_RESULT,
  WAX_USER_ERROR,
  OPEN_LOGIN_MODAL,
  CLOSE_LOGIN_MODAL,
  GET_KEIKE_TOKENS,
  GET_KEIKE_TOKENS_SUCCESS,
  GET_KEIKE_TOKENS_ERROR,
  GET_KEIKE_TOKENS_CANCELLED,
  GET_TIME_TO_CLAIM,
  GET_TIME_TO_CLAIM_SUCCESS,
  GET_TIME_TO_CLAIM_ERROR,
  GET_TIME_TO_CLAIM_CANCELLED,
  CLAIM_TOKENS,
  CLAIM_TOKENS_SUCCESS,
  CLAIM_TOKENS_ERROR,
  CLAIM_TOKENS_CANCELLED,
  CLEAN_ERRORS,
  GET_USER_WALLET_COINS,
  GET_USER_WALLET_COINS_SUCCESS,
  GET_USER_WALLET_COINS_ERROR,
  GET_USER_WALLET_COINS_CANCELLED,
  GET_USER_CONTRACT_COINS,
  GET_USER_CONTRACT_COINS_SUCCESS,
  GET_USER_CONTRACT_COINS_ERROR,
  GET_USER_CONTRACT_COINS_CANCELLED,
  STAKE_COINS_START,
  STAKE_COINS_SUCCESS,
  STAKE_COINS_ERROR,
  STAKE_COINS_CANCELLED,
  UNSTAKE_COINS_START,
  UNSTAKE_COINS_SUCCESS,
  UNSTAKE_COINS_ERROR,
  UNSTAKE_COINS_CANCELLED,
  CLAIM_ALL_TOKENS_START,
  CLAIM_ALL_TOKENS_SUCCESS,
  CLAIM_ALL_TOKENS_ERROR,
  GET_COINS_VALUES_START,
  GET_COINS_VALUES_SUCCESS,
  GET_COINS_VALUES_ERROR,
  GET_COINS_VALUES_CANCELLED,
};
