import actionTypes from '../actions/actionTypes';

import { call, cancelled, put, select, takeLatest } from 'redux-saga/effects';
import { getCoinsValueRequest } from '../wax/getCoinsValueRequest';
import { filterCoinsValue } from '../filters';

export function* resolveGetCoinsValues() {
  try {
    console.log(`Get coins values`);
    const userAccount = yield select((state) => state.login.userAccount);
    const result = yield call(getCoinsValueRequest, userAccount);
    const resultFiltered = filterCoinsValue(result);

    yield put({
      type: actionTypes.GET_COINS_VALUES_SUCCESS,
      payload: resultFiltered,
    });
  } catch (error) {
    console.error('ERROR ON GET COINS', error);
    yield put({
      type: actionTypes.GET_COINS_VALUES_ERROR,
      payload: error,
    });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actionTypes.GET_COINS_VALUES_CANCELLED,
      });
    }
  }
}

export function* getCoinsValuesSaga() {
  console.log(`Get coins values start`);

  yield takeLatest(actionTypes.GET_COINS_VALUES_START, resolveGetCoinsValues);
}
