import { DRIP_ACC } from '../constants';
export function unstakeCoinsWaxCommand({ userAccount, coins }) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await userAccount.signTransaction(
        {
          actions: [
            {
              account: DRIP_ACC,
              name: 'unstakenfts',
              authorization: [
                {
                  actor: userAccount.accountName,
                  permission: userAccount.requestPermission,
                },
              ],
              data: {
                wallet: userAccount.accountName,
                asset_ids: coins,
              },
            },
          ],
        },
        {
          blocksBehind: 3,
          expireSeconds: 30,
        }
      );
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
}
