import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useSelector, useDispatch } from 'react-redux';
import {
  closeLoginModal,
  loginUserWithAnchorAction,
  loginUserWithWaxAction,
} from '../actions/loginActions';
import { Button } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'space-between',
};

export default function LoginModal() {
  const isOpen = useSelector((state) => state.login.isLoginModalOpen);
  const dispatch = useDispatch();
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={() => dispatch(closeLoginModal())}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Button onClick={() => dispatch(loginUserWithWaxAction())}>
            WAX Cloud Wallet
          </Button>
          <Button onClick={() => dispatch(loginUserWithAnchorAction())}>
            Anchor
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
