///atomicassets/v1/assets?collection_name=applefaucets&owner=icarotest123&page=1&limit=100&order=desc&sort=name

import axios from 'axios';
import { COLLECTION_NAME } from '../constants';

export class Axios {
  constructor(base_url) {
    this.base_url = base_url;
  }

  static getInstance(base_url = 'https://wax.api.atomicassets.io') {
    if (!this.instance) {
      this.instance = axios.create({
        baseURL: base_url,
      });
    }
    return this.instance;
  }
}

export function getCollection(wallet) {
  return new Promise(async (resolve, reject) => {
    let path = `atomicassets/v1/assets?collection_name=${COLLECTION_NAME}&owner=${wallet}&page=1&limit=100&order=desc&sort=name&nonce=${Date.now()}`;
    try {
      const axios = Axios.getInstance();
      const { data } = await axios.get(path);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
