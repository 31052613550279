import { Box, Button, Card, Grid } from "@mui/material";
import React, { useEffect, useRef } from "react";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions } from "@mui/material";
import { useDispatch } from "react-redux";
import { stakeCoins, unstakeCoins } from "../actions/loginActions";
import Countdown from "react-countdown";
function Coin({ coin, stake, next_claim }) {
  const clockRef = useRef();
  const dispatch = useDispatch();
  const [name, description] = coin.name.split("-");
  useEffect(() => {
    if (clockRef.current && clockRef.current.timer) {
      clockRef.current.start();
    }
  }, [coin, clockRef]);
  return (
    <Grid item xs={1} sm={2} md={3}>
      <Card sx={{ minWidth: 150, maxWidth: 200, margin: "auto", height: 350 }}>
        <CardActionArea>
          <CardMedia
            sx={{ width: 150, margin: "auto", marginTop: "15px" }}
            component="img"
            height="140"
            image={`https://gateway.ipfs.io/ipfs/${coin.img}`}
            alt={coin.name}
            margin="auto"
          />
          <Box textAlign="center">
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {name?.trim()}
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle1"
                component="div"
                sx={{
                  marginBottom: "15px",
                }}
              >
                {description?.trim()}
              </Typography>
              {!stake && (
                <Countdown
                  autoStart
                  date={new Date(`${next_claim}Z`).getTime()}
                  ref={clockRef}
                >
                  <Typography>Ready to claim!</Typography>
                </Countdown>
              )}
            </CardContent>
          </Box>
        </CardActionArea>
        <CardActions>
          <Button
            size="small"
            color="primary"
            sx={{ width: 150, margin: "auto" }}
            onClick={() => {
              stake
                ? dispatch(stakeCoins([coin.asset_id]))
                : dispatch(unstakeCoins([coin.asset_id]));
            }}
          >
            {stake ? "Stake" : "Unstake"}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}

export default Coin;
