import { Box, Grid, Typography } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Coin from './Coin';
import './Coins.css';

function Coins({ from }) {
  const { accountName } = useSelector((state) => state.login);
  const { coins } = useSelector((state) => state);
  const [avaliableAmount, setAvaliableAmount] = useState(0);
  const [totalAmount, setTotalAmountt] = useState(0);
  const dateNow = new Date().getTime();

  useEffect(() => {
    if (coins && coins[from] && coins['values']) {
      let avaliableAmount = 0;
      let totalAmount = 0;
      Object.keys(coins[from]).map((coin) => {
        const c_coin = coins[from][coin];
        const c_template = coins[from][coin].template_id;
        const date = new Date(`${c_coin.next_claim}Z`).getTime();
        if (dateNow >= date) {
          avaliableAmount += Number(coins.values[c_template].amount);
        }
        totalAmount += Number(coins.values[c_template].amount);
      });
      setAvaliableAmount(avaliableAmount);
      setTotalAmountt(totalAmount);
      console.log(avaliableAmount, totalAmount);
    }
  }, [coins]);

  return (
    <Box sx={{ width: '100%', marginTop: '15px', margin: '15px' }}>
      {coins && coins[from] && Object.keys(coins[from]).length > 0 && (
        <Fragment>
          <Typography gutterBottom variant='h6' component='div'>
            <h2>
              The Public Faucet is now closed
              <br />
              Stake your Founders' Coins here and scroll down to collect daily.
            </h2>
            {from === 'wallet'
              ? `COINS IN WALLET: ${accountName}: ${totalAmount} KEIKI`
              : `COINS STAKED: ${avaliableAmount}/${totalAmount} KEIKI`}
          </Typography>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 16 }}
          >
            {coins &&
              coins[from] &&
              coins['values'] &&
              Object.keys(coins[from]).map((coin) => {
                const c_coin = coins[from][coin];

                return (
                  <Coin
                    key={coin}
                    coin={c_coin}
                    stake={from === 'wallet'}
                    next_claim={c_coin.next_claim}
                  />
                );
              })}
          </Grid>
        </Fragment>
      )}
    </Box>
  );
}

export default Coins;
