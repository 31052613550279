import * as types from './actionTypes';

export const getWaxUserAction = () => {
  return {
    type: types.GET_WAX_USER,
  };
};

export const loginUserWithWaxAction = () => {
  return {
    type: types.LOGIN_USER_WAX,
  };
};

export const loginUserWithAnchorAction = () => {
  return {
    type: types.LOGIN_USER_ANCHOR,
  };
};

export const logoutUserAction = () => {
  return {
    type: types.LOGOUT_USER,
  };
};

export const loginSuccess = ({ userAccount, walletType }) => {
  return {
    type: types.LOGIN_SUCCESS,
    payload: {
      userAccount,
      walletType,
    },
  };
};

export const loginError = (error) => {
  return {
    type: types.LOGIN_ERROR,
    payload: error,
  };
};

export const loginCancelled = () => {
  return {
    type: types.LOGIN_CANCELLED,
  };
};

export const openLoginModal = () => {
  return {
    type: types.OPEN_LOGIN_MODAL,
  };
};

export const closeLoginModal = () => {
  return {
    type: types.CLOSE_LOGIN_MODAL,
  };
};

export const claimTokens = () => {
  return {
    type: types.CLAIM_TOKENS,
  };
};

export const cleanErros = () => {
  return {
    type: types.CLEAN_ERRORS,
  };
};

export const getUserWalletCoins = () => {
  return {
    type: types.GET_USER_WALLET_COINS,
  };
};

export const getUserContractCoins = () => {
  return {
    type: types.GET_USER_CONTRACT_COINS,
  };
};

export const stakeCoins = (coins) => {
  return {
    type: types.STAKE_COINS_START,
    payload: coins,
  };
};

export const unstakeCoins = (coins) => {
  return {
    type: types.UNSTAKE_COINS_START,
    payload: coins,
  };
};

export const claimAllTokens = (coins) => {
  return {
    type: types.CLAIM_ALL_TOKENS_START,
    payload: coins,
  };
};
