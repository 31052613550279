import "./App.css";
import MenuAppBar from "./components/MenuAppBar";
import { Fragment, useEffect } from "react";
import LoginModal from "./components/LoginModal";
import AlertMessage from "./components/AlertMessage";
import { useDispatch, useSelector } from "react-redux";
import { LOGGED_IN } from "./constants";
import Coins from "./components/Coins";
import { claimAllTokens, getUserWalletCoins } from "./actions/loginActions";
import { getUserContractCoins } from "./actions/loginActions";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import Redeemer from "./components/Redeemer";

function App() {
  const dispatch = useDispatch();
  const { hasError, errorMessage, status, accountName } = useSelector(
    (state) => state.login
  );
  const stakedCoins = useSelector((state) => state.coins.contract);

  useEffect(() => {
    if (accountName === null || accountName === undefined) return;
    dispatch(getUserWalletCoins());
    dispatch(getUserContractCoins());
  }, [accountName]);

  function claimCoins() {
    const timeNow = new Date();
    const coinList = [];
    Object.keys(stakedCoins).map((coin) => {
      if (new Date(stakedCoins[coin].next_claim) <= timeNow) {
        coinList.push(coin);
      }
    });
    dispatch(claimAllTokens(coinList));
  }

  return (
    <Fragment>
      {hasError && <AlertMessage message={errorMessage} open={hasError} />}
      <MenuAppBar />
      {status === LOGGED_IN && (
        <Fragment>
          <Redeemer />
          <Coins from="wallet" />
          <Coins from="contract" />
          {/*<Redeemer />*/}
          <Box textAlign="center">
            <Button variant="contained" disableElevation onClick={claimCoins}>
              Claim All
            </Button>
          </Box>
        </Fragment>
      )}
      <LoginModal />
    </Fragment>
  );
}

export default App;
