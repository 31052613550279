import { UAL } from 'universal-authenticator-library';

export class UalAdaptor extends UAL {
  isAutologin = false;

  static SESSION_EXPIRATION_KEY = 'ual-session-expiration';
  static SESSION_AUTHENTICATOR_KEY = 'ual-session-authenticator';
  static SESSION_ACCOUNT_NAME_KEY = 'ual-session-account-name';

  static AUTHENTICATOR_LOADING_INTERVAL = 250;

  constructor(userCallbackHandler, chains, appName, authenticators) {
    super(chains, appName, authenticators);
    this.userCallbackHandler = userCallbackHandler;
    this.loginUser = this.loginUser.bind(this);
  }

  init() {
    const authenticators = this.getAuthenticators();

    // perform this check first, if we're autologging in we don't render a dom
    if (!!authenticators.autoLoginAuthenticator) {
      this.isAutologin = true;
      this.loginUser(authenticators.autoLoginAuthenticator);
      this.activeAuthenticator = authenticators.autoLoginAuthenticator;
    } else {
      // check for existing session and resume if possible
      this.attemptSessionLogin(authenticators.availableAuthenticators);
    }
  }

  attemptSessionLogin(authenticators) {
    const sessionExpiration =
      localStorage.getItem(UalAdaptor.SESSION_EXPIRATION_KEY) || null;
    if (sessionExpiration) {
      // clear session if it has expired and continue
      if (new Date(sessionExpiration) <= new Date()) {
        localStorage.clear();
      } else {
        const authenticatorName = localStorage.getItem(
          UalAdaptor.SESSION_AUTHENTICATOR_KEY
        );
        const sessionAuthenticator = authenticators.find(
          (authenticator) =>
            authenticator.constructor.name === authenticatorName
        );
        const accountName =
          localStorage.getItem(UalAdaptor.SESSION_ACCOUNT_NAME_KEY) ||
          undefined;
        this.loginUser(sessionAuthenticator, accountName);
      }
    }
  }

  async loginUser(authenticator, accountName) {
    let users;

    // set the active authenticator so we can use it in logout
    this.activeAuthenticator = authenticator;

    const invalidateSeconds = this.activeAuthenticator.shouldInvalidateAfter();
    const invalidateAt = new Date();
    invalidateAt.setSeconds(invalidateAt.getSeconds() + invalidateSeconds);

    localStorage.setItem(
      UalAdaptor.SESSION_EXPIRATION_KEY,
      invalidateAt.toString()
    );
    localStorage.setItem(
      UalAdaptor.SESSION_AUTHENTICATOR_KEY,
      authenticator.constructor.name
    );

    try {
      await this.waitForAuthenticatorToLoad(authenticator);

      if (accountName) {
        users = await authenticator.login(accountName);

        localStorage.setItem(UalAdaptor.SESSION_ACCOUNT_NAME_KEY, accountName);
      } else {
        users = await authenticator.login();
      }

      if (users.rpc === undefined) {
        this.logoutUser();
      }
      // send our users back
      this.userCallbackHandler(users);
      return users;
    } catch (e) {
      console.error('Error', e);
      this.clearStorageKeys();
      throw e;
    }
  }

  async waitForAuthenticatorToLoad(authenticator) {
    return new Promise((resolve) => {
      if (!authenticator.isLoading()) {
        resolve();
        return;
      }
      const authenticatorIsLoadingCheck = setInterval(() => {
        if (!authenticator.isLoading()) {
          clearInterval(authenticatorIsLoadingCheck);
          resolve();
        }
      }, UalAdaptor.AUTHENTICATOR_LOADING_INTERVAL);
    });
  }

  async logoutUser() {
    if (!this.activeAuthenticator) {
      throw Error(
        'No active authenticator defined, did you login before attempting to logout?'
      );
    }

    this.activeAuthenticator.logout();

    this.clearStorageKeys();
  }

  clearStorageKeys() {
    // clear out our storage keys
    localStorage.removeItem(UalAdaptor.SESSION_EXPIRATION_KEY);
    localStorage.removeItem(UalAdaptor.SESSION_AUTHENTICATOR_KEY);
    localStorage.removeItem(UalAdaptor.SESSION_ACCOUNT_NAME_KEY);
  }
}
