import actionTypes from '../actions/actionTypes';
import { getCollection } from '../utils/Axios';
import { filterCoins } from '../filters/index';

import { call, cancelled, put, select, takeLatest } from 'redux-saga/effects';

export function* resolveGetUserWalletCoins() {
  try {
    const userAccount = yield select(
      (state) => state.login.userAccount.accountName
    );
    const result = yield call(getCollection, userAccount);
    const resultFiltered = filterCoins(result.data);

    yield put({
      type: actionTypes.GET_USER_WALLET_COINS_SUCCESS,
      payload: resultFiltered,
    });
  } catch (error) {
    console.error('ERROR ON GET COINS', error);
    yield put({
      type: actionTypes.GET_USER_WALLET_COINS_ERROR,
      payload: error,
    });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actionTypes.GET_USER_CONTRACT_COINS_CANCELLED,
      });
    }
  }
}

export function* getUserWalletCoins() {
  yield takeLatest(
    actionTypes.GET_USER_WALLET_COINS,
    resolveGetUserWalletCoins
  );
}
