import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers/rootReducer';
import { claimAllTokensSaga } from '../sagas/claimAllTokensSaga';
import { getCoinsValuesSaga } from '../sagas/getCoinsValueSaga';
import { getUserContractCoins } from '../sagas/getUserContractCoins';
import { getUserWalletCoins } from '../sagas/getUserWalletCoins';
import { stakeCoinsSaga } from '../sagas/stakingCoinsSaga';
import { unstakeCoinsSaga } from '../sagas/unstakingCoinsSaga';
import {
  claimTokens,
  getKeikeToken,
  getTimeToClaim,
  logActions,
  loginFlow,
} from './../sagas';

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  const composeArgs = [applyMiddleware(sagaMiddleware)];
  if (window && window.__REDUX_DEVTOOLS_EXTENSION__) {
    composeArgs.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  const store = createStore(rootReducer, compose.apply(undefined, composeArgs));

  sagaMiddleware.run(logActions);

  sagaMiddleware.run(loginFlow);
  sagaMiddleware.run(getKeikeToken);
  sagaMiddleware.run(getTimeToClaim);
  sagaMiddleware.run(claimTokens);
  sagaMiddleware.run(getUserWalletCoins);
  sagaMiddleware.run(getUserContractCoins);
  sagaMiddleware.run(stakeCoinsSaga);
  sagaMiddleware.run(unstakeCoinsSaga);
  sagaMiddleware.run(claimAllTokensSaga);
  sagaMiddleware.run(getCoinsValuesSaga);

  return store;
}
