export const LOGGED_IN = 'Logged in';
export const LOGGED_OUT = 'Logged out';
export const LOGIN_ERROR = 'Login error';
export const LOGIN_CANCELLED = 'Login cancelled';
export const CLOUD_WALLET = 'Cloud wallet';
export const ANCHOR = 'ANCHOR';
export const APP_NAME = 'Orchid Hunter';
export const TOKEN_SYMBOL = 'KEIKI';
export const TOKEN_ACC = 'orchidtokens';
export const DRIP_ACC = 'orchidfaucet';
export const COLLECTION_NAME = 'orchidhunter';
export const COMMON = 1;
export const UNCOMMON = 2;
export const RARE = 3;
export const LEGENDARY = 4;
export const EPIC = 5;
export const GHOST = 6;
