import actionTypes from '../actions/actionTypes';

import { call, cancelled, put, select, takeLatest } from 'redux-saga/effects';
import { getUserContractCoinsRequest } from '../wax/getUserContractCoins';
import { filterCoinsStaked } from '../filters';

export function* resolveGetUserContractCoins() {
  try {
    const userAccount = yield select((state) => state.login.userAccount);
    const result = yield call(getUserContractCoinsRequest, userAccount);
    const resultFiltered = filterCoinsStaked(result);
    yield put({
      type: actionTypes.GET_USER_CONTRACT_COINS_SUCCESS,
      payload: resultFiltered,
    });
  } catch (error) {
    console.error('ERROR ON GET COINS', error);
    yield put({
      type: actionTypes.GET_USER_CONTRACT_COINS_ERROR,
      payload: error,
    });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actionTypes.GET_USER_CONTRACT_COINS_CANCELLED,
      });
    }
  }
}

export function* getUserContractCoins() {
  yield takeLatest(
    actionTypes.GET_USER_CONTRACT_COINS,
    resolveGetUserContractCoins
  );
}
