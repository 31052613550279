import actionTypes from '../actions/actionTypes';
import {
  LOGGED_IN,
  LOGGED_OUT,
  LOGIN_CANCELLED,
  LOGIN_ERROR,
} from '../constants';
import initialState from './initialState';

export default function userRole(state = initialState.login, action) {
  let newState;
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      newState = {
        ...state,
        status: LOGGED_IN,
        userAccount: action.payload.userAccount[0],
        accountName: action.payload.userAccount[0].accountName,
        walletType: action.payload.userAccount[0].constructor.name,
      };
      return newState;
    case actionTypes.LOGOUT_USER:
      return initialState.login;
    case actionTypes.LOGIN_ERROR:
      newState = {
        ...state,
        status: LOGIN_ERROR,
        isLogged: false,
        accountName: undefined,
        userAccount: undefined,
        walletType: undefined,
        hasError: true,
        errorMessage: action.payload.error.message,
      };
      return newState;
    case actionTypes.LOGIN_CANCELLED:
      newState = {
        ...state,
        status: LOGIN_CANCELLED,
        isLogged: false,
        accountName: undefined,
        userAccount: undefined,
        walletType: undefined,
      };
      return newState;
    case actionTypes.OPEN_LOGIN_MODAL:
      newState = {
        ...state,
        isLoginModalOpen: true,
      };
      return newState;
    case actionTypes.CLOSE_LOGIN_MODAL:
      newState = {
        ...state,
        isLoginModalOpen: false,
      };
      return newState;
    case actionTypes.GET_KEIKE_TOKENS_SUCCESS:
      newState = {
        ...state,
        balances: action.payload,
        needReload: false,
      };
      return newState;
    case actionTypes.GET_TIME_TO_CLAIM_SUCCESS:
      newState = {
        ...state,
        timeToClaim: action.payload,
        needReload: false,
      };
      return newState;
    case actionTypes.CLAIM_TOKENS_SUCCESS:
      newState = {
        ...state,
        needReload: true,
      };
      return newState;

    case actionTypes.STAKE_COINS_ERROR:
    case actionTypes.UNSTAKE_COINS_ERROR:
    case actionTypes.CLAIM_ALL_TOKENS_ERROR:
    case actionTypes.CLAIM_TOKENS_ERROR:
      newState = {
        ...state,
        hasError: true,
        errorMessage: action.payload.cause.json.error.details[0].message,
      };
      console.log('newstate', newState);
      return newState;

    case actionTypes.CLEAN_ERRORS:
      newState = {
        ...state,
        hasError: false,
        errorMessage: undefined,
      };
      return newState;
    default:
      return state;
  }
}
