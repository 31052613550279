export const COINS = {
  //Calyzon
  510933: {
    name: "Calyzon - Common",
    img: "QmRFc1TXna78zLE54Do8nCiEQxuhdBviGSuLwoz5RhawhL",
    rarity: "common",
  },
  510937: {
    name: "Calyzon - Epic",
    img: "QmZoyu7F4FJ4Q9YzHiBCVyiwcfMinUBc43sbXdjPuBR2Q3",
    rarity: "epic",
  },
  510926: {
    name: "Calyzon - Ghost",
    img: "QmcV58h8LQwLrzdQ7gG57gQJQ2UESecBGMpNc538TDPFL1",
    rarity: "ghost",
  },
  510927: {
    name: "Calyzon - Legenday",
    img: "Qmc57TTEsTTeYep2uVzQmhxJhcSqZyQZrcTFiz4LfKNcDt",
    rarity: "legendary",
  },
  510929: {
    name: "Calyzon - Rare",
    img: "QmR4oUjKW9xFwV2TrJXtsgJKXgNtCaVDyYg6jS4Dayzq5t",
    rarity: "rare",
  },
  510932: {
    name: "Calyzon - Uncommon Founders' Coin",
    img: "QmW6waQDNsUURFzjXbQQwMjirfFCXVFiNxRBA7NPsXjdWM",
    rarity: "uncommon",
  },
  //Ha-Radia
  510950: {
    name: "Ha-Radia - Common Founders' Coin",
    img: "QmQQgZ9T2LzfqKyjLKiuqMKNNgZav5HPakMPAxV9iDA3Uh",
    rarity: "common",
  },
  510956: {
    name: "Ha-Radia - Epic Founders' Coin",
    img: "QmbcX5k3iyqQP4kYJtwVTGyhq98PAzfquqoDoTYtVLYqW7",
    rarity: "epic",
  },
  510958: {
    name: "Ha-Radia - Ghost Founders' Coin",
    img: "QmTPox8qiEdboyVSfs9P7WSX4zFEF5qsxosPDTkeTRte2F",
    rarity: "ghost",
  },
  510957: {
    name: "Ha-Radia - Legenday Founders' Coin",
    img: "QmRQMkdvEk5HgoMEYVc52kRx3xqonwEMiyjgUoihPfkeee",
    rarity: "legendary",
  },
  510955: {
    name: "Ha-Radia - Rare Founders' Coin",
    img: "QmabNtFYmEXMcDLqz1cj291KpRKbiXFhpHEjiSxA5QjXNG",
    rarity: "rare",
  },
  510952: {
    name: "Ha-Radia - Uncommon Founders' Coin",
    img: "QmUf4a7aLwP6pNjxugojcDr5a856uhGwMJyB6znUBFWXxx",
    rarity: "uncommon",
  },

  //Ipaci
  510961: {
    name: "Ipaci - Common Founders' Coin",
    img: "QmaMKvqpSczGAnF1tju8m5MKzpkx68VCmQXzPzQPAKG22i",
    rarity: "common",
  },
  510965: {
    name: "Ipaci - Legendary Founders' Coin",
    img: "QmP1vm54kwa9vzemhjGEgjDz9zCj4P6jqV4TytHEFbJk8F",
    rarity: "legendary",
  },
  510968: {
    name: "Ipaci - Ghost Founders' Coin",
    img: "QmPjME3BRcBU8KQWnhCB2ipRLhAETtSMKp6R8KkydW1Zpq",
    rarity: "Ghost",
  },
  510966: {
    name: "Ipaci - Legenday Founders' Coin",
    img: "QmVEL61MPomi8dxH5EucgGgXQuRtPbdULtss21bvhZRA29",
    rarity: "legendary",
  },
  510964: {
    name: "Ipaci - Rare Founders' Coin",
    img: "QmezZ1DFUuZfdh2EDFA1BnK5jasJ1nP8Xw4D8nHJYRS5xL",
    rarity: "rare",
  },
  510962: {
    name: "Ipaci - Uncommon Founders' Coin",
    img: "QmewM5gxB2Lu8f9rJq2mbh2wyuEsEfpW362KWXCYJGZFkK",
    rarity: "uncommon",
  },
  //Nari
  510903: {
    name: "Nari - Common Founders' Coin",
    img: "QmNh3eJ3RdWfNNqHcWULdrtynz3nM5ewovSGc45GjybKwH",
    rarity: "common",
  },
  510896: {
    name: "Nari - Epic Founders' Coin",
    img: "QmY3CHwiowToYAY75m3TfxsUrmMd7QzbiQJk57t1kt6aWF",
    rarity: "epic",
  },
  510891: {
    name: "Nari - Ghost Founders' Coin",
    img: "QmQanV8McmVgjYPwiqkaQyRh2WWuDJpgbqCuqZUZipWuVX",
    rarity: "ghost",
  },
  510894: {
    name: "Nari - Legenday Founders' Coin",
    img: "Qma5TttjX8zqGWKT5AGayL88XBaGnHgbuc8Hu4cowVsSGe",
    rarity: "legendary",
  },
  510898: {
    name: "Nari - Rare Founders' Coin",
    img: "QmctXg4KyS3prEMwSSyxsEdKReRYR272d1fz4jcoNeqQRY",
    rarity: "rare",
  },
  510901: {
    name: "Nari - Uncommon Founders' Coin",
    img: "QmRt9JzVgSCvssdJ81iMrk6r6EsJNEMPTqSUf47A34FC9h",
    rarity: "uncommon",
  },
  //Phylaxi
  510931: {
    name: "Phylaxi - Common Founders' Coin",
    img: "QmcBvcy1mn3ajDzP3cYCSBp3nQrqFMv8huxkqNJmYSk45k",
    rarity: "common",
  },
  510941: {
    name: "Phylaxi - Epic Founders' Coin",
    img: "QmVWUEoHYnaJfYwYh5qX621U7D9FoCrGvUn2b1yNRjWbcr",
    rarity: "epic",
  },
  510944: {
    name: "Phylaxi - Ghost Founders' Coin",
    img: "QmaSw1AyuKugqjwPEiUFqhvPLYJvx8VNTXZFbTmTAzpcVE",
    rarity: "ghost",
  },
  510943: {
    name: "Phylaxi - Legenday Founders' Coin",
    img: "QmTWNHEVCyoKJztDoky96oxprXnF777oD22bMdmvC3ueqp",
    rarity: "legendary",
  },
  510939: {
    name: "Phylaxi - Rare Founders' Coin",
    img: "QmRYSfLCccDwppDboQ3AeHwtTHwGFRJbYqhDESMQsW1Eo8",
    rarity: "rare",
  },
  510934: {
    name: "Phylaxi - Uncommon Founders' Coin",
    img: "QmVV1fFHHK1eejqbDDLjRFiVTVmaAmKLRkQfRZ1eKCB6Bq",
    rarity: "uncommon",
  },
  //Rachanté
  510902: {
    name: "Rachanté - Common Founders' Coin",
    img: "QmNWjov5ngM9gKRoRSKuMUieTfRU3ELoWv37RR7hMA4nxF",
    rarity: "common",
  },
  510910: {
    name: "Rachanté - Epic Founders' Coin",
    img: "QmXXcbYssQYrrHJE3kj9Avd2zmw8UpR6hdtcYsRCC8kTr1",
    rarity: "epic",
  },
  510913: {
    name: "Rachanté - Ghost Founders' Coin",
    img: "QmQ5ipWXhQr2YUZB4YWscBrAnbptvq5LubWkmuP3q6d1Av",
    rarity: "ghost",
  },
  510912: {
    name: "Rachanté - Legenday Founders' Coin",
    img: "QmS5R9xMradBr7AFyh6qPoEBctKFCY7UdrswHsyEGgeW6D",
    rarity: "legendary",
  },
  510908: {
    name: "Rachanté - Rare Founders' Coin",
    img: "QmNZofzkDngp9FwFqybuysg7u6qhKuQiRaDKBYLrKegk54",
    rarity: "rare",
  },
  510905: {
    name: "Rachanté - Uncommon Founders' Coin",
    img: "QmPaxKhY2EQoNv5YCN4rWbGSEzUdoW4bPJVMPBN6M5WLst",
    rarity: "uncommon",
  },
  //Tolum
  510892: {
    name: "Tolum - Common Founders' Coin",
    img: "QmdPE3Ktq7heANhR36K8AHA73z249aY9vpdCYQcaoQ5c7B",
    rarity: "common",
  },
  510888: {
    name: "Tolum - Epic Founders' Coin",
    img: "QmVrhcGhwVAFSwhh24iiuAbcLPNzZoB3sro3Lo1PXx2Uz3",
    rarity: "epic",
  },
  510899: {
    name: "Tolum - Ghost Founders' Coin",
    img: "QmbiZc8bdzLed7NGCpZkztTDKCwNp1ADB6BDTjQ5xg8UMr",
    rarity: "ghost",
  },
  510897: {
    name: "Tolum - Legenday Founders' Coin",
    img: "QmPL4Cs2127mbhwBi2hg2rv94C8NR5S71QHxKafh3nJ8e5",
    rarity: "legendary",
  },
  510887: {
    name: "Tolum - Rare Founders' Coin",
    img: "QmVao7TEYmTSbzQCSkSYmfkkEpXaQhQD2QRKQ5CscbDGpu",
    rarity: "rare",
  },
  510886: {
    name: "Tolum - Uncommon Founders' Coin",
    img: "QmbWNZP5nQChzdJy3bEGDeyY14o7L6NuisnCDBw62o4TU9",
    rarity: "uncommon",
  },
  //Vandran
  510925: {
    name: "Vandran - Common Founders' Coin",
    img: "QmRzZGpUPtcHckzDKgJpyp7oDBD2xtYP6A3VrLnSd1ERXn",
    rarity: "common",
  },
  510919: {
    name: "Vandran - Epic Founders' Coin",
    img: "Qmd583fHvQ6oZdNTzdKMAkvGVXKfUucikhgngs7449MAyj",
    rarity: "epic",
  },
  510915: {
    name: "Vandran - Ghost Founders' Coin",
    img: "QmSe9mDKnESPspB4Ngz5Q8TLrKDbWUBByvjZb8aUb8FtLd",
    rarity: "gosth",
  },
  510917: {
    name: "Vandran - Legenday Founders' Coin",
    img: "Qmf5sihdrVD8ueYx2fi9KnbGNTKcrjK245Vc93opZpTZzb",
    rarity: "legendary",
  },
  510921: {
    name: "Vandran - Rare Founders' Coin",
    img: "QmWyFf8atties1hSrWBT5SkK2vsyocyba6JFWhae5YF3Fd",
    rarity: "rare",
  },

  510924: {
    name: "Vandran - Uncommon Founders' Coin",
    img: "QmbN2MNzKBDNjBFJwEVTt417cvweBBBcymmVmTnSLvPPJ1",
    rarity: "uncommon",
  },
};

export const COINS_TEMPLATES = Object.keys(COINS);
