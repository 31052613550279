import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
} from 'redux-saga/effects';
import {
  closeLoginModal,
  loginCancelled,
  loginError,
  loginSuccess,
} from '../actions/loginActions';
import actionTypes from '../actions/actionTypes';
import { APP_NAME, DRIP_ACC, TOKEN_ACC, TOKEN_SYMBOL } from '../constants';
import Wallets from '../ual/Wallets';
export function anchorAPI(loginWith) {
  return new Promise(async (resolve, reject) => {
    try {
      let walletToUse;
      if (loginWith === actionTypes.LOGIN_USER_WAX) {
        walletToUse = Wallets.getWax();
      } else if (loginWith === actionTypes.LOGIN_USER_ANCHOR) {
        walletToUse = Wallets.getAnchor();
      }
      const users = await Wallets.getWallet().loginUser(walletToUse, APP_NAME);
      resolve(users);
    } catch (error) {
      console.error('ERROR', error);

      reject(error);
    }
  });
}

export function* resolveLogin(loginWith) {
  try {
    const userAccount = yield call(anchorAPI, loginWith);
    yield put(loginSuccess({ userAccount, walletType: loginWith }));
    yield put({ type: actionTypes.GET_KEIKE_TOKENS });
    yield put({ type: actionTypes.GET_TIME_TO_CLAIM });
    yield put({ type: actionTypes.GET_COINS_VALUES_START });
  } catch (error) {
    console.error('ERROR', error);

    yield put(loginError(error));
  } finally {
    yield put(closeLoginModal());
    if (yield cancelled()) {
      yield put(loginCancelled());
    }
  }
}

export function* loginFlow() {
  while (true) {
    const loginWith = yield take([
      actionTypes.LOGIN_USER_WAX,
      actionTypes.LOGIN_USER_ANCHOR,
    ]);
    const task = yield fork(resolveLogin, loginWith.type);

    const action = yield take([
      actionTypes.LOGOUT_USER,
      actionTypes.LOGIN_ERROR,
      actionTypes.LOGIN_CANCELLED,
    ]);
    if (action.type === actionTypes.LOGOUT_USER) {
      Wallets.getWallet().logoutUser();
    }
    if (action.type === actionTypes.LOGIN_CANCELLED) {
      yield cancel(task);
    }
  }
}

// ------------ TOKENS ------------------

export function requestGetKeikeToken(userAccount) {
  return new Promise(async (resolve, reject) => {
    try {
      const balance = await userAccount.rpc.get_currency_balance(
        TOKEN_ACC,
        userAccount.accountName,
        TOKEN_SYMBOL
      );
      resolve(balance);
    } catch (error) {
      reject(error);
    }
  });
}

export function* resolveGetKeikeToken() {
  try {
    const userAccount = yield select((state) => state.login.userAccount);
    const balance = yield call(requestGetKeikeToken, userAccount);
    yield put({ type: actionTypes.GET_KEIKE_TOKENS_SUCCESS, payload: balance });
  } catch (error) {
    console.error('ERROR', error);

    yield put({ type: actionTypes.GET_KEIKE_TOKENS_ERROR, payload: error });
  } finally {
    if (yield cancelled()) {
      yield put(loginCancelled());
    }
  }
}

export function* getKeikeToken() {
  while (true) {
    yield take(actionTypes.GET_KEIKE_TOKENS);
    const task = yield fork(resolveGetKeikeToken);

    const action = yield take([
      actionTypes.GET_KEIKE_TOKENS_SUCCESS,
      actionTypes.GET_KEIKE_TOKENS_ERROR,
      actionTypes.GET_KEIKE_TOKENS_CANCELLED,
    ]);
    if (action.type === actionTypes.LOGIN_CANCELLED) {
      yield cancel(task);
    }
  }
}

export function* logActions() {
  while (true) {
    const action = yield take('*');
    console.log(action);
  }
}

// --------------- Time to Claim ----------

export function requestTimeToClaim(userAccount) {
  return new Promise(async (resolve, reject) => {
    try {
      const { rows } = await userAccount.rpc.get_table_rows({
        json: true,
        code: DRIP_ACC,
        scope: DRIP_ACC,
        lower_bound: userAccount.accountName,
        upper_bound: userAccount.accountName,
        table: 'claims',
        limit: 1,
      });
      resolve(rows);
    } catch (error) {
      console.error('ERROR', error);

      reject(error);
    }
  });
}

export function* resolveTimeToClaim() {
  try {
    const userAccount = yield select((state) => state.login.userAccount);
    const rows = yield call(requestTimeToClaim, userAccount);
    yield put({
      type: actionTypes.GET_TIME_TO_CLAIM_SUCCESS,
      payload: rows[0]?.next_claim || Math.floor(new Date().getTime() / 1000),
    });
    // FIX ERROR
  } catch (error) {
    console.error('ERROR', error);

    yield put({
      type: actionTypes.GET_TIME_TO_CLAIM_ERROR,
      payload: error,
    });
  } finally {
    if (yield cancelled()) {
      yield put(loginCancelled());
    }
  }
}

export function* getTimeToClaim() {
  while (true) {
    yield take(actionTypes.GET_TIME_TO_CLAIM);
    const task = yield fork(resolveTimeToClaim);

    const action = yield take([
      actionTypes.GET_TIME_TO_CLAIM_SUCCESS,
      actionTypes.GET_TIME_TO_CLAIM_CANCELLED,
      actionTypes.GET_TIME_TO_CLAIM_ERROR,
    ]);
    if (action.type === actionTypes.GET_TIME_TO_CLAIM_CANCELLED) {
      yield cancel(task);
    }
  }
}

// --------------- Claim ----------

export function claimTokensFunction(userAccount) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await userAccount.signTransaction(
        {
          actions: [
            {
              account: DRIP_ACC,
              name: 'claim',
              authorization: [
                {
                  actor: userAccount.accountName,
                  permission: userAccount.requestPermission,
                },
              ],
              data: {
                wallet: userAccount.accountName,
              },
            },
          ],
        },
        {
          blocksBehind: 3,
          expireSeconds: 30,
        }
      );
      resolve(response);
    } catch (error) {
      console.error('ERROR', error);

      reject(error);
    }
  });
}

export function* resolveClaimTokens() {
  try {
    const userAccount = yield select((state) => state.login.userAccount);
    yield call(claimTokensFunction, userAccount);
    const result = yield put({
      type: actionTypes.CLAIM_TOKENS_SUCCESS,
    });
  } catch (error) {
    console.error('ERROR', error);
    yield put({
      type: actionTypes.CLAIM_TOKENS_ERROR,
      payload: error,
    });
  } finally {
    if (yield cancelled()) {
      yield put(loginCancelled());
    }
  }
}

export function* claimTokens() {
  while (true) {
    yield take(actionTypes.CLAIM_TOKENS);
    const task = yield fork(resolveClaimTokens);

    const action = yield take([
      actionTypes.CLAIM_TOKENS_SUCCESS,
      actionTypes.CLAIM_TOKENS_CANCELLED,
      actionTypes.CLAIM_TOKENS_ERROR,
    ]);
    if (action.type === actionTypes.CLAIM_TOKENS_CANCELLED) {
      yield cancel(task);
    }
  }
}
